/* Googlefont Poppins CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
@import "~bootstrap-icons/font/bootstrap-icons.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100%;
  font: 1rem/1.56 'Roboto, Verdana, Arial, sans-serif';
  line-height: 1.56;
}

a {
  cursor: pointer;
  transition: all .5s ease;
  word-wrap: break-word;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

h2 {
  color: #2b0a3d;
  font-size: 28px;
  font-weight: 500;
}

p {
  letter-spacing: .9px;
  color: #000;
  line-height: 25px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 1em auto;
}

/* nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 70px;
  background: #3E8DA8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 99;
} */

nav .navbar {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  /* background: red; */
  padding: 0 0px;
}

.navbar .logo a {
  font-size: 30px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

nav .navbar .nav-links {
  width: 100%
}

nav .navbar .links {
  display: flex;
  padding: 0;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin: 0;
  list-style: none;
  align-items: stretch;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

nav .navbar .links li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

nav .navbar .links>li:first-child {
  padding-left: 0px !important;
}

nav .navbar .links li a {
  text-decoration: none;
  white-space: nowrap;
  color: #2280b3;
  font-size: 1rem; //change 
  font-weight: 400; //change
}

nav .navbar .links li a:hover {
  color: rgb(0, 0, 0);
}

.htmlCss-sub-menu li:hover .more-arrow {
  transition: 5s;
  animation: myfirst 2s 1;
}

@keyframes myfirst {
  100% {
    transform: rotate(180deg);
  }
}

.active {
  color: rgb(0, 0, 0) !important;
}

.menu-adjust {
  white-space: normal !important;
  line-height: normal;
}

.links li:hover .htmlcss-arrow,
.links li:hover .js-arrow {
  transform: rotate(180deg);
}

nav .navbar .links li .arrow {
  //  background: red; 
  height: 100%;
  width: 22px;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  color: #0070ad;
  transition: all 0.3s ease;
}

nav .navbar .links li .sub-menu {
  position: absolute;
  top: 50px;
  left: 0;
  line-height: 40px;
  background: #ffffff;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 0 0 4px 4px;
  display: none;
  z-index: 9999999;
  width: auto;
  padding-top: 0px;
}

nav .navbar .links li:hover .htmlCss-sub-menu,
nav .navbar .links li:hover .js-sub-menu {
  display: block;
}

.navbar .links li .sub-menu li {
  padding: 0 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar .links li .sub-menu a {
  color: #0070ad;
  font-size: 15px;
  font-weight: 400;
}

.navbar .links li .more-sub-menu a {
  color: #0070ad;
  font-size: 1 5px;
  font-weight: 500;
  text-shadow: normal;
}

.navbar .links li .sub-menu .more-arrow {
  line-height: 20px;
}

//.navbar .links li .htmlCss-more-sub-menu {
/* line-height: 40px; */
//}

.navbar .links li .sub-menu .more-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  border-radius: 0 4px 4px 4px;
  z-index: 1;
  display: none;
}

.links li .sub-menu .more:hover .more-sub-menu {
  display: block;
}

.navbar .search-box {
  position: relative;
  height: 40px;
  width: 40px;
}

.navbar .search-box i {
  position: relative;
  height: 100%;
  width: 100%;
  line-height: 40px;
  text-align: center;
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.navbar .search-box .input-box {
  position: absolute;
  right: calc(100% - 40px);
  top: 80px;
  height: 60px;
  width: 300px;
  background: #3E8DA8;
  border-radius: 6px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}

.navbar.showInput .search-box .input-box {
  top: 65px;
  opacity: 1;
  pointer-events: auto;
  background: #3E8DA8;
}

.search-box .input-box::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  background: #3E8DA8;
  right: 10px;
  top: -6px;
  transform: rotate(45deg);
}

.search-box .input-box input {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  height: 35px;
  width: 280px;
  outline: none;
  padding: 0 15px;
  font-size: 16px;
  border: none;
}

.navbar .nav-links .sidebar-logo {
  display: none;
}

.navbar .bx-menu {
  display: none;
}

/* header css */
.brand-logo {
  width: 50%;
  float: left;
}

.search {
  width: 50%;
  float: left;
}

.search a {
  color: #000;
  text-decoration: none;
}

header {
  display: inline-block;
  width: 100%;
  margin: auto;
  padding: 8px 0px 0px 0px;
}

.border-bottom {
  border-bottom: solid 1px #ccc3;
}

.d-flex {
  display: flex !important;
}

.container {
  max-width: 1200px;
  justify-content: space-between;
  align-items: center;
}

.header-top {
  padding: 20px 0px 10px 0px;
}

.text-right {
  text-align: right;
}

.banner {
  padding: 6px 0px;
  background-color: #f7f7f7;
  margin-bottom: 40px;
}

.banner h1 {
  color: #2b0a3d;
  font-size: 50px;
  font-weight: 500;
}

.bx-search {
  vertical-align: middle;
  margin-right: 10px;
  font-weight: bold !important;

}

.search-box {
  padding-right: 0%;
}


/* end  dheader css */

/* body start */
.heading {
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 4px;
  font-style: normal;

}

.heading1 {
  margin-top: 20px;
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 10px;
  font: 2.25rem/1.33 'Roboto, Verdana, Arial, sans-serif';
  letter-spacing: .2px;
  color: #2b0a3d;
  margin-bottom: 40px;
}

.heading1::after {
  content: "";
  display: block;
  margin-top: 8px;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background-color: #2b0a3d;
}

.sub-heading {
  margin-top: 20px;
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 10px;
  // font-style: oblique;
  // font-family:sans-serif;
  font-size: 18px;
  font: 1.25rem/1.5 'Roboto, Verdana, Arial, sans-serif';
  word-break: break-word;

}

.sub-heading:hover {
  text-decoration: underline;
  color: #007aff;
}

.normal-text {

  padding-left: 10px;
  margin-left: 10px;
  letter-spacing: 0.2px;
  font: 1rem/1.67 'Roboto, Verdana, Arial, sans-serif';
  // padding-bottom:5px;
  // padding-top: 0px;
  font-size: 15px;
  margin-top: 20px;
}

// .paragraph{
//   padding-left: 10px;
//   margin-left: 10px;
//   letter-spacing: 0.2px;
//   font: 1rem/1.67 'Roboto,Verdana,Arial,sans-serif';
//   padding-bottom:80px;
//   padding-top: 80px;
//   font-size: 15px;
//   margin-top: 20px;
// }

/* body ends */
.mid-container {
  flex-direction: column;
  min-height: 73vh;
}

.article-text {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -15px;
  list-style: none;
  counter-reset: i
}

.article-text li {
  flex: 0 0 100%;
  padding: 0 5px 0 5px;
  position: relative;
  margin-bottom: 20px
}

.article-text li:last-child {
  margin-bottom: 0
}

.article-text li:before {
  width: 45px;
  line-height: 38px;
  padding-top: 5px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  display: block;
  color: #0070ad;
  border: 1px solid #0070ad
}

.article-text li h3 {
  font: 1.25rem/1.5 'Roboto, Verdana, Arial, sans-serif';
  margin: 10px 0 15px;
  color: #2b0a3d
}

.article-text p,
.article-text ul,
.article-text ol {
  margin-bottom: 30px
}

.article-text p:last-child,
.article-text h2:last-child,
.article-text ul:last-child,
.article-text ol:last-child {
  margin-bottom: 0
}

.article-text p {
  font: 1rem/1.56 'Roboto, Verdana, Arial, sans-serif';
  letter-spacing: .2px;
  color: #000
}

.article-text span {
  color: #000 !important;
  font-family: 'Roboto, Verdana, Arial, sans-serif' !important
}

.article-text strong {
  font-family: 'Roboto, Verdana, Arial, sans-serif'
}

.article-text ul {
  margin-left: 30px;
  list-style-type: disc
}

.article-text ul li p:first-child {
  display: inline
}

.article-text ol {
  margin-left: 30px
}

.article-text ol li {
  padding-left: 8px
}

.article-text hr {
  border: 1px solid #8d8a8a
}

.article-text a:not(.section__button):not(.social-buttons__button) {
  line-height: 1.56;
  letter-spacing: .2px;
  color: #0070ad;
  transition: all .5s ease;
  text-decoration: none;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0
}

.article-text a:not(.section__button):not(.social-buttons__button):hover,
.article-text a:not(.section__button):not(.social-buttons__button):focus {
  text-decoration: none;
  border-bottom: 1px solid #2b0a3d;
  padding-bottom: 0;
  color: #2b0a3d
}

.article-text h1,
.article-text h2,
.article-text h3,
.article-text h4,
.article-text h5,
.article-text h6 {
  color: #2b0a3d;
  margin-top: 48px;
  margin-bottom: 16px
}

.article-text h1:first-child,
.article-text h2:first-child,
.article-text h3:first-child,
.article-text h4:first-child,
.article-text h5:first-child,
.article-text h6:first-child {
  margin-top: 0
}

.article-text h1 {
  font: 1.75rem/1.43 'Roboto, Verdana, Arial, sans-serif'
}

.article-text h2 {
  font: 1.5rem/1.42 'Roboto, Verdana, Arial, sans-serif'
}

.article-text h3 {
  font: 1.25rem/1.5 'Roboto, Verdana, Arial, sans-serif'
}

.article-text h4 {
  font: 1.125rem/1.56 'Roboto, Verdana, Arial, sans-serif'
}

.article-text h5 {
  font: 1.125rem/1.56 'Roboto, Verdana, Arial, sans-serif'
}

.article-text h6 {
  font: 1.125rem/1.56 'Roboto, Verdana, Arial, sans-serif'
}

.article-text pre {
  font-size: .9375rem;
  letter-spacing: .2px;
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace
}

.article-text img {
  display: block
}

.article-text figcaption {
  color: #666;
  font: .75rem/1.2 'Roboto, Verdana, Arial, sans-serif';
  padding-top: 4px
}

.alignleft {
  float: left;
  margin: 1em 2em 1em 0;
}

img,
svg {
  max-width: calc(100% + .1px);
  height: auto;
}

.legend {
  font: 13px/2 'Roboto, Verdana, Arial, sans-serif';
  position: static;
  display: block;
  color: #666;
  order: 1;
  margin: 0 0 10px;
}

.top-section-page-heading {
  font-size: 3em;
}

.top-section-page {
  background-color: #eee;
  background-position: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

.hero_careers__pic {
  background-repeat: no-repeat;
  background-size: 35% !important;
  background-position: 100% 100%;
}

.hero_careers--red {
  background-color: #ff304d;
}

.hero_careers--darkpurple {
  background-color: #2b0a3d;
}

.hero_careers--green {
  background-color: #95e616;
}

.legend-radio {
  font: 13px/2 'Roboto, Verdana, Arial, sans-serif';
  color: #666;
  margin: 0 0 10px;
}

.radioCls {
  margin: 0 0 5px;
  top: -8px;
  font: .75rem/1.33 'Roboto, Verdana, Arial, sans-serif';
  letter-spacing: .2px;
  position: absolute;
  z-index: 1;
  left: 8px;
  color: #666;
  padding: 0 8px;
  background: #fff;
}

.full-width-image {
  width: 100%;
}

.top-margin-30 {
  margin-top: 30px;
}

.top-margin-50 {
  margin-top: 50px;
}


.bottom-margin-10 {
  margin-bottom: 10px;
}

.bottom-margin-20 {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.padding-bottom-20 {
  padding-bottom: 20px
}

.padding-bottom-50 {
  padding-bottom: 50px
}

.padding-bottom-60 {
  padding-bottom: 60px
}

.padding-bottom-80 {
  padding-bottom: 80px
}

.padding-bottom-90 {
  padding-bottom: 90px
}

.padding-bottom-100 {
  padding-bottom: 100px
}

.padding-bottom-130 {
  padding-bottom: 130px
}

.padding-bottom-170 {
  padding-bottom: 170px
}

.left-margin-20 {
  margin-left: 20px;
}

.text-area {
  height: 80px !important;
}

.active-link {
  color: #000 !important;
}

.textbox {
  box-shadow: 5px 5px 0 0 rgb(43 10 61 / 20%);
  height: 48px;
  border: 1px solid #2b0a3d;
  padding: 8px 24px 8px 16px;
  background: 0 0;
  width: 100%;
  transition: all .5s ease;
  margin-bottom: 20px;
  ;
}

.textbox.ng-invalid.ng-touched {
  border: 1px solid red;
  box-shadow: 5px 5px 0 0 rgb(255 127 127 / 20%);
}

.submit-button {
  display: inline-block;
  font: 1rem/1 'Roboto, Verdana, Arial, sans-serif';
  width: auto;
  min-width: 195px;
  border-radius: 100px;
  text-align: center;
  white-space: nowrap;
  transition: all .5s ease;
  padding: 0 20px;
  color: #0070ad;
  border: 2px solid #0070ad;
  line-height: 48px;
  height: auto;
  box-shadow: none;
  cursor: pointer;
  margin-bottom: 20px;
  ;
  background-color: white;
}

.htmlCss-sub-menu {
  padding-left: 2rem;
}

@media (max-width:920px) {
  nav .navbar {
    max-width: 100%;
    padding: 5px 25px;
  }

  nav .navbar .logo a {
    font-size: 27px;
  }

  nav .navbar .links li {
    padding: 0 10px;
    white-space: nowrap;
  }

  nav .navbar .links li a {
    font-size: 15px;
  }

  .container {
    width: 100%;
  }

  header {
    padding: 8px 10px 0px 10px;
  }

  nav .navbar {
    background: #0070ad;
  }

  nav .navbar .links li a {
    color: #ffffff;
  }

  nav .navbar .links li .arrow {
    color: #fff;
  }

  nav .navbar .links li .sub-menu {
    width: 100%;
    line-height: 27px;
    padding-top: 11px;
  }

  .navbar .links li .sub-menu a {
    font-size: 16px;
  }


  .mid-container {
    padding: 20px;
  }


}

@media (max-width:800px) {
  //nav {
  /* position: relative; */
  //}

  .navbar .bx-menu {
    display: block;
  }

  nav .navbar .nav-links {
    position: fixed;
    top: 0;
    left: -100%;
    display: block;
    max-width: 350px;
    width: 100%;
    background: #3E8DA8;
    line-height: 40px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    z-index: 1000;
  }

  .navbar .nav-links .sidebar-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidebar-logo .logo-name {
    font-size: 25px;
    color: #fff;
  }

  .sidebar-logo i,
  .navbar .bx-menu {
    font-size: 25px;
    color: #fff;
  }

  nav .navbar .links {
    display: block;
    margin-top: 20px;
  }

  nav .navbar .links li .arrow {
    line-height: 40px;
  }

  nav .navbar .links li {
    display: block;
  }

  nav .navbar .links li .sub-menu {
    position: relative;
    top: 0;
    box-shadow: none;
    display: none;
  }

  nav .navbar .links li .sub-menu li {
    border-bottom: none;
  }

  .navbar .links li .sub-menu .more-sub-menu {
    display: none;
    position: relative;
    left: 0;
  }

  .navbar .links li .sub-menu .more-sub-menu li {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .links li:hover .htmlcss-arrow,
  .links li:hover .js-arrow {
    transform: rotate(0deg);
  }

  .navbar .links li .sub-menu .more-sub-menu {
    display: none;
  }

  .navbar .links li .sub-menu .more span {
    /* background: red; */
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }

  .links li .sub-menu .more:hover .more-sub-menu {
    display: none;
  }

  nav .navbar .links li:hover .htmlCss-sub-menu,
  nav .navbar .links li:hover .js-sub-menu {
    display: none;
  }

  .navbar .nav-links .links .htmlCss-sub-menu.show1,
  .navbar .nav-links .links .js-sub-menu.show3,
  .navbar .nav-links .links .more .more-sub-menu.show2 {
    display: block;
  }



  .rotate180 {
    transform: rotate(180deg) !important;
  }

  .rotate90 {
    transform: rotate(90deg) !important;
  }


  .htmlCss-sub-menu {
    padding-left: 0;
  }

  nav .navbar .links li .more-arrow {
    color: #0070ad;
  }

}

@media (max-width:480px) {
  nav .navbar .nav-links {
    max-width: 100%;
  }

  .language-dropdown {
    width: 115px;
    float: right;
  }

  .logo {
    max-width: 50%;
  }
}

p {
  overflow-wrap: break-word;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.social-buttons__button--fb {
  stroke: none;
}

.social-buttons__button {
  font-size: 1.4rem;
  line-height: 30px;
}

.social-buttons__button:last-child {
  margin-bottom: 0;
}


.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container-full {
  margin: 0 50px;
}

.section {
  padding-top: 48px;
  padding-bottom: 48px;
}


.section__block {
  margin-bottom: 24px;
}

.section--grey {
  background: #ececec;
}

.section__height {
  margin: auto;
  height: 97%;
}

.section__height2 {
  margin: auto auto 20px;
  height: 47%;
}

.featured-jobs-dv {
  background-color: #2b0a3d;
  border: 1px solid #7d38ba;
  margin-bottom: 10px;
}

.padding-0-10 {
  padding: 0 10px;
}

.padding-10 {
  padding: 10px;
}

.heading2 {
  margin-top: 20px;
  margin-left: 10px;
  padding-left: 10px;
  padding-top: 10px;
  font: 2.25rem/1.33 'Roboto, Verdana, Arial, sans-serif';
  letter-spacing: .2px;
  color: #ffffff;
  margin-bottom: 40px;
}

.heading2::after {
  content: "";
  display: block;
  margin-top: 8px;
  width: 48px;
  height: 4px;
  border-radius: 2px;
  background-color: #ffffff;
}

.nav--cloud-services.cs_nav--cloud-services .cs_menu-cloud-services-social {
  opacity: 1;
  display: flex;
  justify-content: flex-end !important;
  transition: all .5s ease;
}

.nav--cloud-services.cs_nav--cloud-services .cs_menu-cloud-services-social li {
  display: inline-block;
  line-height: 0;
  text-align: center;
  margin: 0 12px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.hero-inset__intro {
  font: 1.75rem/1.43 'Roboto, Verdana, Arial, sans-serif';
  color: #2b0a3d;
  margin-top: 24px;
  padding: 0px 0px 24px 0px;
}

.hero-inset__intro p {
  line-height: 40px;
  color: #2b0a3d;
}

.article-text {
  margin-bottom: 30px;
  display: list-item;
}

.article-text ul {
  margin-left: 0px;
  list-style-type: disc;
}

.article-text ol,
.article-text p,
.article-text ul {
  margin-bottom: 30px;
}

.article-text li {
  margin-bottom: 0px;
}

.justify-content-center {
  justify-content: center !important;
}



.w3-padding {
  padding: 8px 16px !important;
}

.w3-third {
  float: left;
  width: auto;
}

.w3-border-red {
  border-bottom: 6px solid #ff0000;
}

.w3-third:hover {
  background-color: #e5e2e2;
}

.w3-container,
.w3-panel {
  padding: 0.01em 16px;
}

.card_tab {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 360px;
}

.card_tab__title {
  font: 1.25rem/1.5 'Roboto, Verdana, Arial, sans-serif';
  color: #2b0a3d;
  padding: 0 32px;
  margin-top: 16px;
}

.card_tab__inner {
  height: 100%;
  width: 100%;
}

.card_tab__hover {
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  transition: all .5s ease;
}

.card_tab:hover .card_tab__hover {
  transform: translateX(0);
  top: 0;
}

.pf_forms__container {
  clear: both;
  position: relative;
  margin-bottom: 32px;
}

.pf_field {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  margin-bottom: 32px;
  position: relative;
}

.pf_field>label {
  display: block;
}

.pf_field label {
  margin: 0 0 5px;
  top: -8px;
  font: 0.75rem/1.33 'Roboto, Verdana, Arial, sans-serif';
  letter-spacing: .2px;
  position: absolute;
  z-index: 1;
  left: 8px;
  color: #666;
  padding: 0 8px;
  background: #fff;
}

.section--grey .pf_field label {
  background: #ececec;
}

.pf_field__input {
  box-shadow: 5px 5px 0 0 rgb(43 10 61 / 20%);
  height: 48px;
  border: 1px solid #2b0a3d;
  color: #000;
  font: 1rem/1.3 'Roboto, Verdana, Arial, sans-serif';
  padding: 8px 24px 8px 16px;
  background: 0 0;
  width: 100%;
  transition: all .5s ease;
}

.pf_has_errors label {
  color: #ff304d !important;
}

.pf_field--checkboxes.pf_has_errors label:before {
  border: 2px solid #ff304d !important;
  box-shadow: 5px 5px 0 0 rgb(255 48 77 / 20%) !important;
  box-sizing: border-box;
}

.pf_field__input.empty {
  background-color: transparent;
  border: solid 1px #666;
}

.pf_has_errors input,
.pf_has_errors textarea {
  border-bottom: 2px solid #ff304d !important;
  box-shadow: 5px 5px 0 0 rgb(255 48 77 / 20%) !important;
}

.pf_error_shadow,
.pf_has_errors .select2 {
  box-sizing: border-box;
}

.pf_has_errors .pf_error,
.pf_error.pf_email_error {
  color: #ff304d !important;
  margin-top: 8px;
  font: 0.75rem/1.33 'Roboto, Verdana, Arial, sans-serif';
  order: 2;
}

.pf_field textarea {
  min-height: 100px;
}

.pf_field--radios ul li,
.pf_field--checkboxes ul li,
.pf_consent__wrapper {
  margin-left: 0;
  position: relative;
  display: block;
}

.pf_field input[type=radio],
.pf_field input[type=checkbox] {
  opacity: 0;
  position: absolute;
  box-sizing: border-box;
  padding: 0;
  overflow: visible;
}

.pf_field--radios input,
.pf_field--radios label,
.pf_field--checkboxes input,
.pf_field--checkboxes label,
.pf_consent__wrapper input,
.pf_consent__wrapper label {
  font-size: 13px;
  cursor: pointer;
  position: static;
  display: inline-block;
  color: #666;
}

.pf-required--symbol,
label .pf-required--symbol,
span.pf-required--symbol {
  content: "*";
  display: inline-block;
  position: relative;
  color: #e80000 !important;
  order: 2;
  left: 2px;
}

.pf_field--radios input:checked+label:before,
.pf_field--checkboxes input:checked+label:before,
.pf_consent__wrapper input:checked+label:before {
  content: "";
  border: 1px solid #000;
  box-shadow: 5px 5px 0 0 rgb(43 10 61 / 20%), inset 0 0 0 5px #fff;
  background: #2b0a3d;
}

.pf_field--radios input+label:before,
.pf_field--checkboxes input+label:before,
.pf_consent__wrapper input+label:before {
  content: "";
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  box-shadow: 5px 5px 0 0 rgb(43 10 61 / 20%);
  font-size: 1.25rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 15px;
  margin-bottom: 5px;
  transition: .5s all;
}


.social-buttons {
  display: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  z-index: 10;
  text-align: center;
}

.blog-article-text .article-text,
.main-content-text,
.page-template-default .article-text,
.single-post .article-text,
.single-resource .article-text {
  min-height: 208px;
}

.article-text blockquote {
  margin-bottom: 48px;
}

.article-text blockquote>p {
  font: 1.25rem/1.5 'Roboto, Verdana, Arial, sans-serif';
  color: #0070ad;
  position: relative;
  margin-bottom: 16px;
}

blockquote.article-text__quote--line p {
  position: relative;
  padding-left: 27px;
}

blockquote.article-text__quote--line p:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 2px;
  height: 100%;
  background: #0070ad;
}

.social-buttons--fixed {
  display: flex;
  top: 0;
  left: 0;
  position: fixed;
  background: transparent;
  flex-direction: column;
  width: 40px;
}

.careers__jobinfo {
  padding: 32px 24px;
  color: #0070ad;
  margin-bottom: 50px;
}

.careers__jobinfo p {
  color: #0070ad;
}

.careers__jobinfo,
.careers__jobsearch {
  background-color: #f3f3f3;
}

.careers__jobinfo__header {
  margin-top: 28px;
  font: 14px/1 'Roboto,Verdana,Arial,sans-serif';
  color: #2b0a3d !important;
  margin-bottom: 5px;
}

.careers__jobinfo__header:first-child {
  margin-top: 0;
}

.social-buttons--fixed-bottom {
  position: absolute;
  bottom: 70px;
  display: none;
  z-index: 99;
}

.pf_field--radios input+label:before {
  border-radius: 50%;
}

.pf_field legend {
  font: 13px/2 'Roboto, Verdana, Arial, sans-serif';
  position: static;
  display: block;
  color: #666;
  order: 1;
  margin: 0 0 10px;
  width: auto;
}

.pf_field--radios ul,
.pf_field--checkboxes ul {
  width: 100%;
  order: 2;
  margin-left: 0;
  list-style: none !important;
  list-style-type: none;
}

.pf_form__form ul {
  width: 100%;
  order: 2;
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.pf_form__form ul li,
.pf_form__form ul li label{
  padding: 0px 0px 0px 0px;
}

.video_full {
  margin-bottom: 28px;
  background: no-repeat 50%/cover;
  position: relative;
  overflow: hidden;
  transition: all .5s ease;
  min-height: 370px;
  background-color: #ececec;
}

.video_full .video_full__embed.active {
  opacity: 1;
  z-index: 10;
}

.component_video {
  position: relative;
  margin-bottom: 80px;
}

.video_full__embed {
  width: 100%;
  height: 370px;
  position: absolute;
  opacity: 0;
  padding: 0;
  top: 0;
  left: 0;
  transition: opacity .5s ease;
  z-index: 0;
}

.video_full__embed embed,
.video_full__embed iframe,
.video_full__embed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-inset__date svg {
  font-size: 1.25rem;
  position: relative;
  top: 0px;
  margin-right: 5px;
  height: 21px;
}

.video_full__inner {
  background-color: hsla(0, 0%, 100%, .85);
  padding: 236px 0 0;
  min-height: 370px;
  transition: background .5s ease;
  position: relative;
  z-index: 5;
}

.video_full__info {
  margin-bottom: 8px;
  transition: all .5s ease;
}

.component_video__title--player {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: relative;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  left: 45%;
}

.video_full__button {
  display: none;
  cursor: pointer;
}

.video_full__section-title-player {
  font-size: 3.5rem;
  font-weight: 700;
}

.video_full__text {
  display: none;
  font: 1.5rem/1.54 'Roboto, Verdana, Arial, sans-serif';
  transition: all .5s ease;
  color: #2b0a3d;
  margin: 16px 0 0;
}

.component_video__title--player i {
  color: #2b0a3d;
  font-size: 34px;
  position: relative;
  top: 8px;
  left: 20px;
}


.footer__social_icons_list svg:hover,
.hero_default__social-media svg:hover,
.social-buttons__button svg:hover {
  border-radius: 20px;
}

.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 576px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .social-buttons__button {
    width: 40px;
    flex: 1 0 40px;
    padding-top: 9px;
    border-radius: 50%;
  }

  .social-buttons {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 40px;
  }

  blockquote.article-text__quote--line p {
    padding-left: 0;
  }

  blockquote.article-text__quote--line p:before {
    left: -27px;
  }

  .social-buttons--fixed-bottom {
    display: flex;
  }

  .social-buttons--fixed {
    left: auto;
    padding-top: 60px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .video_full__embed {
    height: 350px;
  }

  .video_full__inner {
    padding: 130px 0 100px;
    min-height: 350px;
  }

  .video_full__button {
    margin: 32px 0 0;
  }

  .video_full__text {
    display: block;
  }

  .video_full.active {
    min-height: 350px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
    width: 1140px !important;
    max-width: 100%;
  }

  .video_full__embed {
    height: 720px;
  }

  .video_full.active {
    min-height: 720px;
  }
}

@media (min-width: 1400px) {
  .cs_nav--cloud-services ul {
    max-width: 100%;
  }
}